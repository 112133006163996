import './Memory.css';
import {Link, useNavigate} from "react-router-dom";
import Header from '../header/Header';
import Footer from '../footer/Footer';
import bg from '../images/mountains2.jpg';
import {db} from '../firebase'
import { useEffect, useState } from "react";

const BRIGHTNESS = 100;

const COLORS = [{r:255,g:0,b:0,brightness:BRIGHTNESS},
                {r:0,g:255,b:0,brightness:BRIGHTNESS},
                {r:0,g:0,b:255,brightness:BRIGHTNESS},
                {r:255,g:255,b:0,brightness:BRIGHTNESS},
                {r:255,g:0,b:255,brightness:BRIGHTNESS},
                {r:0,g:255,b:255,brightness:BRIGHTNESS},];

const NUM_COLORS = 4;
let game_colors = [];
const TIME_BETWEEN_COLORS = 1500;
let highscores = [];


function ReactionTime(){
    const[sequence, setSequence] = useState([]);
    const[isActive, setIsActive] = useState(false);

    let currGuess = 0;


    useEffect(()=>{
        window.addEventListener('visibilitychange', ()=>{
            restartGame();
        })


        db.collection("lightbulb").doc("busy").onSnapshot((doc)=>{
            console.log(doc.data());
            updatePlay(doc.data().isbusy);
        })


        db.collection("lightbulb").doc("highscores").onSnapshot((doc)=>{
            highscores = doc.data().highscores;

        })
    }, [])


    function setHighscores(scores){
        document.getElementById("highscore-list").innerText = '';
        if(scores != undefined){
        for(let i = 0; i < scores.length; i++){
            document.getElementById("highscore-list").innerHTML += "<p class='text text_light text_lg highscore__item'>" + scores[i].name + ": " + scores[i].score + "</p>" +"<br/>";
        }
    }
    }
    
    function updatePlay(isBusy){
        console.log(isBusy);
        let button = document.getElementById("start-button");
        if(isBusy && button != null){ 
            button.disabled = true;
            button.innerText = "Please Wait";
        }else if(button != null){
            button.disabled = false;
            button.innerText = "Start Game";
        }
    }

    function setColors(num){
        game_colors = [];
        let available_colors = [...COLORS];
        console.log(COLORS);
        for(let i = 0; i < num; i++){
            let idx = Math.floor(Math.random()*available_colors.length);
            game_colors.push(available_colors.at(idx));
            available_colors.splice(idx,1);
        }
        console.log(game_colors);
    }

    function startGame(){
        setColors(NUM_COLORS);
        generateNext();
        setIsActive(true);
        setBusy(true);
    }

    function displayColors(){
        let seq = []
        for(let i = 0; i < sequence.length; i++){
            seq.push(sequence[i]);
            seq.push({r:0,g:0,b:0,brightness:0})
        }
        console.log(seq);

        updateLight(seq, TIME_BETWEEN_COLORS, seq.length);
        bgColorSeq(sequence.length, 0);
    }

    function bgColorSeq(iterations, i=0){
        if(iterations>1)setTimeout(()=>bgColorSeq(iterations-1,i+1), TIME_BETWEEN_COLORS);
        changeColor(sequence[i], iterations<=1);
    }

    function openGuessMenu(){
        document.getElementById('round').innerText = sequence.length;
        document.getElementById('guess_menu').style.display='flex';
        document.getElementById('guess_menu').style.pointerEvents='none';
        setTimeout(() => {
            document.getElementById('guess_menu').style.pointerEvents='auto';
        }, 600);
        document.getElementById('bg').style.backgroundColor='rgb(0,0,0)'
        document.getElementById('guess_menu').style.opacity=1;
        document.getElementsByClassName('memory__guess-indicators')[0].style.opacity = 1;
    }

    function closeGuessMenu(){
        document.getElementById('guess_menu').style.opacity=0;
        setTimeout(() => {
            document.getElementById('guess_menu').style.display='none';
        }, 600);
    }

    function changeColor(color, isFinal) {
        let id = null;
        const elem = document.getElementById("bg");
        let i = 0;
        let rgb = splitRGB(elem.style.backgroundColor);
        let r = rgb[0]*1;
        let g = rgb[1]*1;
        let b = rgb[2]*1;

        const RESOLUTION = 200;
        const STEP_TIME = 5;

        let stepR = (0-r)/(RESOLUTION/4);
        let stepG = (0-g)/(RESOLUTION/4);
        let stepB = (0-b)/(RESOLUTION/4);

        let stepR2 = (color.r-0)/((RESOLUTION/4)*3);
        let stepG2 = (color.g-0)/((RESOLUTION/4)*3);
        let stepB2 = (color.b-0)/((RESOLUTION/4)*3);


        clearInterval(id);
        id = setInterval(frame, STEP_TIME);
    

        function frame() {
          if (i == RESOLUTION) {
            clearInterval(id);
            if(isFinal)setTimeout(openGuessMenu, TIME_BETWEEN_COLORS-RESOLUTION*STEP_TIME);
          } else {
            i++;

            if(i<RESOLUTION/4){
                r += stepR;
                g += stepG;
                b += stepB;
            }else{
                r += stepR2;
                g += stepG2;
                b += stepB2;
            }

            elem.style.backgroundColor = 'rgb(' + Math.round(r) + ',' + Math.round(g) + ',' + Math.round(b) + ')'
            

          }
        }
      }

      function splitRGB(rgb){
        return rgb.substring(4,rgb.length-1).split(", ");
      }

    function generateNext(){
        let nextColor = game_colors[Math.floor(Math.random()*4)];
        let seq = [...sequence];
        seq.push(nextColor);
        setSequence(seq);
    }

    useEffect(()=>{
        if(sequence.length != 0) displayColors();
    }, [sequence]);



    function colorButtonPress(color){
        if(sequence[currGuess].r == color.r && sequence[currGuess].g == color.g && sequence[currGuess].b == color.b){
            correctGuess();
        }else{
            document.getElementById('indicator-'+currGuess).style.backgroundColor ='rgb(255,0,0)';
            checkHighscore(sequence.length-1);
        }


    }

    function correctGuess(){
        document.getElementById('indicator-'+currGuess).style.backgroundColor = 'rgb(0,255,0)';
        if(currGuess < sequence.length-1){
            currGuess++;
        }else{
            nextRound();
        }

    }

    function nextRound(){
        currGuess = 0;
        document.getElementsByClassName('memory__guess-indicators')[0].style.opacity = 0;
        let indicators = document.getElementsByClassName('memory__guess-indicator');
        setTimeout(()=>{
            for(let i = 0; i < indicators.length; i++){
                indicators[i].style.backgroundColor = "rgb(255,255,255)";
            }
        }, 500)

        setTimeout(()=>{
            closeGuessMenu();
            generateNext();
        }, 250)


        
    }

    function checkHighscore(currScore){
        let change = false;
        for(let i = 0; i < 3; i++){
            if(highscores == undefined || highscores[i] == undefined || highscores[i].score < currScore){
                newHighscore(i, currScore);
                change = true;
                break;
            }
        }

        if(!change) restartGame();
    }

    function newHighscore(i, val){
        document.getElementById("new-highscore").style.display="flex";
        document.getElementById("submit-highscore").onclick = ()=>{submitHighscore(i, val)}
    }
    
    function restartGame(){
        setColors();
        setBusy(false);
        closeGuessMenu();
        setIsActive(false);
        setSequence([]);
    }

    function openRules(){
        document.getElementById('rules').style.display = 'block';
    }

    function closeRules(){
        document.getElementById('rules').style.display = 'none';
    }

    function openScores(){
        setHighscores(highscores);
        document.getElementById('highscores').style.display = 'block';
    }

    function closeScores(){
        document.getElementById('highscores').style.display = 'none';
    }

    function submitHighscore(i, score){
        document.getElementById("new-highscore").style.display="none";
        let name = document.getElementById("name").value;
        if(highscores != undefined)highscores.splice(i, 0, {name:name,score:score});
        else highscores = [{name:name,score:score}];
        if(highscores.length >= 4)highscores.splice(3, 1)
        db.collection("lightbulb").doc("highscores").update(
            {highscores}
        );
        restartGame();
    }

    return (
        <div className="page page_100vh" id="bg" style={{backgroundColor:"rgb(0,0,0)"}}>
            
            <div className="memory" id='guess_menu'>
                <div className="new-highscore" id="new-highscore">
                    <p className="title title_light title_md new-highscore_title">New Highscore!</p>
                    <input type="text" className='highscore__textfield' name="name" id="name" />
                    <button className='button button_light highscore__submit' id='submit-highscore'>Submit</button>
                </div>
                <p className="title title_light memory__round" id='round'></p>
                <div className="memory__guess-indicators">
                    {
                        sequence.map((seq, i)=>{
                            return <div className="memory__guess-indicator" id={'indicator-'+i} key={i}></div>
                        })
                    }
                </div>
                <div className="memory__guess">
                    <div className="memory__buttons">
                        {
                            game_colors.map((c)=>{
                                return <div className='memory__button-wrapper' key={c.r+""+c.g+""+c.b}><button className="memory__button" key={c.r+""+c.g+""+c.b} onClick={()=>colorButtonPress({r:c.r, g:c.g, b:c.b})} style={{backgroundColor:'rgb(' + c.r + ',' + c.g + ',' + c.b + ')'}}></button></div>
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="section__content section__content_sm">
                    {((!isActive) ? 
                    
                    <div className='memory-start-wrapper'>
                        <p className="title title_light memory-title">Memory</p>
                        <div className="memory-start-line"></div>
                        <button onClick={startGame} className='memory-start' id="start-button">Start Game</button>
                        <button onClick={openScores} className='memory-highscore' id="help-button">Highscores</button>
                        <button onClick={openRules} className='memory-help' id="help-button">Help</button>
                        <div className="rules" id='rules'>
                            <div className="rules__content">
                                <p className="title title_light title_md rules_title">Rules</p>
                                <p className='text text_lg text_light rules_text'>Welcome to the game of memory! To play memorize the sequence of colors as they are displayed and use the colored buttons to reenter the sequence. Each round one color will be added to the sequence. See how many rounds you can go!</p>
                                <button className='button button_light' onClick={closeRules}>Close</button>
                            </div>
                        </div>
                        <div className="highscores" id="highscores">
                            <div className="highscores__content">
                                <p className="title title_light title_md highscore_title">Highscores</p>
                                <p className="text text_light text_lg" id='highscore-list'></p>
                                <button className='button button_light' onClick={closeScores}>Close</button>
                            </div>
                        </div>
                    </div> 
            
                    
                    
                    
                    : "")}


                </div>
            </div> 


        </div>
    );
}




function lightCycle(finalColor, delay, times){
    let colors = [];

    for(let i = 0; i < times-1; i++){
        colors[i] = {};
        colors[i].r = Math.round(Math.random() * 255);
        colors[i].g = Math.round(Math.random() * 255);
        colors[i].b = Math.round(Math.random() * 255);
        colors[i].brightness = 100;
    }
    console.log(finalColor);
    colors.push(finalColor);

    updateLight(colors, delay, times);
}


function updateLight(color={r:0,g:0,b:0,brightness:100}, delay, times){

    let colors = [];
    let actualDelay;

    for(let i = 0; i < times; i++){
        if(i==0) actualDelay = 0;
        else if((i+1) % 2 == 0) actualDelay = 1400;
        else actualDelay = delay - 1400;


        colors[i] = {
            "r": color[i].r,
            "g": color[i].g,
            "b": color[i].b,
            "brightness": color[i].brightness,
            "delay": actualDelay
        }
    }


    db.collection("lightbulb").doc("state").set({
        "color": colors,
        "number": times
    });
}

function setBusy(state){
    db.collection("lightbulb").doc("busy").set({
        "isbusy": state,
    })
}

export default ReactionTime;